<template>
  <div>
    <notifications group="notification" position="bottom right" />
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b card-stretch">
          <div class="card-header border-0 pt-5">
            <h3 class="card-title">
              <span class="card-label font-weight-bolder text-dark"
                >All Products</span
              >
            </h3>
            <div class="card-toolbar">
              <router-link
                to="/products/new"
                class="btn btn-mc font-weight-bolder font-size-sm mr-5"
              >
                <span class="svg-icon svg-icon-md svg-icon-white">
                  <inline-svg
                    src="media/svg/icons/Communication/Add-user.svg"
                  />
                </span>
                Add New Product
              </router-link>
              <button
                @click.prevent="toggleExportProductsModal"
                class="btn btn-light btn-icon-primary btn-text-primary btn-hover-text-primary font-weight-bold mr-5"
              >
                <i class="flaticon2-file-1"></i>
                Export.xlsx
              </button>
              <button
                @click.prevent="toggleStockModal"
                class="btn btn-light btn-icon-primary btn-text-primary btn-hover-text-primary font-weight-bold"
              >
                <i class="flaticon2-open-box"></i>
                Update Stock
              </button>
            </div>
          </div>
          <div class="card-body pt-2">
            <div class="row mb-5">
              <div class="col-md-5">
                <div class="form-group mb-0">
                  <input
                    @keyup="searchProduct('name')"
                    v-model="nameQuery"
                    id="product_search_by_name"
                    placeholder="Start typing Product Name"
                    class="form-control form-control-solid form-control-lg"
                  />
                </div>
              </div>
              <div class="col-md-5">
                <div class="form-group mb-0">
                  <input
                    @keyup="searchProduct('sku')"
                    v-model="skuQuery"
                    id="product_search_by_sku"
                    placeholder="Start typing Product SKU"
                    class="form-control form-control-solid form-control-lg"
                  />
                </div>
              </div>
              <div class="col-md-2 mt-1">
                <Pagination
                  type="simple"
                  endpoint="/products"
                  filter="?properties[]=id&properties[]=featuredImage&properties[]=name&properties[]=price&properties[]=specialPrice&properties[]=sku&properties[]=category&properties[]=brand&order[name]=asc"
                  @payload="payloadListener"
                ></Pagination>
              </div>
            </div>
            <div class="separator separator-dashed my-5"></div>
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table
                    class="table table-head-custom table-head-bg table-vertical-center"
                  >
                    <thead>
                      <tr class="bg-gray-100 text-left">
                        <th style="min-width: 250px" class="pl-7">
                          <span
                            @click="orderProductsByName"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{ 'text-primary': nameOrder.isActive }"
                            >
                              Name</span
                            >
                            <span
                              class="svg-icon svg-icon-sm  pl-2"
                              :class="{
                                'svg-icon-primary': nameOrder.isActive
                              }"
                            >
                              <inline-svg
                                v-if="nameOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th style="min-width: 130px">
                          <span
                            @click="orderProductsByPrice"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{ 'text-primary': priceOrder.isActive }"
                              >Price</span
                            >
                            <span
                              class="svg-icon svg-icon-sm  pl-2"
                              :class="{
                                'svg-icon-primary': priceOrder.isActive
                              }"
                            >
                              <inline-svg
                                v-if="priceOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th style="min-width: 120px">Brand</th>
                        <th style="min-width: 110px">Root Category</th>
                        <th style="min-width: 110px">Category</th>
                        <th style="min-width: 110px" class="pr-7 text-right">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(product, key) in paginationEntries"
                        :key="key"
                      >
                        <td
                          class="pl-0 py-4"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <div class="d-flex align-items-center">
                            <div class="symbol symbol-50 symbol-light mr-4">
                              <img
                                v-if="
                                  product.featuredImage.hasOwnProperty('src')
                                "
                                :src="
                                  appPath +
                                    '/media/cache/resolve/50x50/' +
                                    product.featuredImage.src
                                "
                                class="h-75 align-self-end"
                                alt=""
                              />
                              <div v-else class="symbol-label"></div>
                            </div>
                            <div>
                              <router-link
                                :to="'/products/edit/' + product.id"
                                class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                >{{ product.name }}</router-link
                              >
                              <span class="text-muted font-weight-bold d-block"
                                >SKU: {{ product.sku }}</span
                              >
                            </div>
                          </div>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            class="text-dark-75 font-weight-bolder d-block font-size-lg"
                            >{{ product.price }} <span>kn</span></span
                          >
                          <span
                            v-if="product.specialPrice"
                            class="text-muted font-weight-bold"
                            >{{ product.specialPrice }} <span>kn</span></span
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            v-if="product.brand"
                            class="text-dark-75 font-weight-bolder d-block font-size-lg"
                            >{{ product.brand.name }}</span
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            v-if="product.category"
                            class="label label-lg label-light-danger label-inline"
                            >{{ product.category.root.title }}</span
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            v-if="product.category"
                            class="label label-lg label-light-primary label-inline"
                            >{{ product.category.title }}</span
                          >
                        </td>
                        <td
                          class="pr-0 text-right"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <a
                            v-if="product.category"
                            :href="
                              `${appPath}/proizvodi/${product.category.root.slug}/${product.category.slug}/${product.slug}`
                            "
                            class="btn btn-icon btn-light btn-hover-light-primary btn-sm mr-3"
                            target="_blank"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/General/Visible.svg"
                              />
                            </span>
                          </a>
                          <router-link
                            :to="'/products/edit/' + product.id"
                            class="btn btn-icon btn-light btn-hover-light-primary btn-sm mr-3"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/Communication/Write.svg"
                              />
                            </span>
                          </router-link>
                          <button
                            @click="removeProduct(product.id)"
                            class="btn btn-icon btn-light btn-hover-light-primary btn-sm"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/General/Trash.svg"
                              />
                            </span>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-if="!isProductsLoaded" class="col-md-12 text-center mt-5">
                <b-spinner
                  variant="primary"
                  type="grow"
                ></b-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-modal id="stock_modal" centered hide-header hide-footer>
          <form>
            <div class="form-group">
              <vue2Dropzone
                class="dropzone dropzone-default dropzone-primary dz-clickable"
                ref="myVueDropzone"
                @vdropzone-file-added="filesAdded"
                @vdropzone-complete="complete"
                @vdropzone-success="success"
                id="dropzone"
                :options="dropzoneOptions"
                :useCustomSlot="true"
              >
                <div class="dropzone-msg dz-message needsclick">
                  <span
                    >Click here to select .csv file or drag .csv file here</span
                  >
                </div>
              </vue2Dropzone>
            </div>
            <div class="form-group mb-0">
              <button
                @click.prevent="updateProductsStock(updateStockFileName)"
                class="btn btn-light-primary btn-block"
              >
                Update
              </button>
            </div>
          </form>
        </b-modal>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-modal id="export_products_modal" centered hide-header hide-footer>
          <form>
            <div class="form-group mb-0">
              <label class="font-weight-bolder">Select category:</label>
              <div v-for="(cat, key) in parentCategories" :key="key">
                <button
                  @click.prevent="exportProducts(cat.id, 'xlsx')"
                  class="btn btn-light-primary btn-block mt-3"
                >
                  {{ cat.title }}
                </button>
              </div>
              <div>
                <button
                  @click.prevent="exportProducts('all', 'xlsx')"
                  class="btn btn-light-danger btn-block mt-3"
                >
                  All categories
                </button>
              </div>
            </div>
          </form>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import vue2Dropzone from 'vue2-dropzone'
import Pagination from '@/view/content/components/pagination/Pagination'

export default {
  name: 'ListProducts',
  components: {
    vue2Dropzone,
    Pagination
  },
  data() {
    return {
      products: [],
      paginationEntries: [],
      appPath: process.env.VUE_APP_ROUTE_API_URL,
      isProductsLoaded: false,
      nameQuery: '',
      skuQuery: '',
      timeout: null,
      debounceMilliseconds: 250,
      priceOrder: {
        query: 'asc',
        isActive: false
      },
      nameOrder: {
        query: 'asc',
        isActive: false
      },
      dropzoneOptions: {
        url: process.env.VUE_APP_API_ENDPOINT + '/upload',
        thumbnailWidth: 150,
        maxFilesize: 1
      },
      updateStockFileName: '',
      isUpdateStockFileSuccessUpdated: false,
      categories: [],
      parentCategories: [],
      childrenCategories: [],
      filteredCategories: [],
      exportCategory: null
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'All Products', route: '/products/list' }
    ])
  },
  methods: {
    async getProducts(resource, slug = '') {
      try {
        return await ApiService.get(resource, slug)
      } catch (e) {
        throw new Error(e)
      }
    },
    async deleteProduct(id) {
      try {
        return await ApiService.delete('/products/' + id)
      } catch (e) {
        throw new Error(e)
      }
    },
    removeProduct(id) {
      const confirmation = confirm('You want delete this product?')
      if (confirmation === false) return

      this.deleteProduct(id).then(({ status }) => {
        if (status === 204) {
          this.products = this.products.filter(product => product.id !== id)
          this.$notify({
            group: 'notification',
            type: 'error',
            title: 'Product success deleted from database'
          })
        }
      })
    },
    searchProduct(type) {
      clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        if (type === 'name') {
          const productsPromise = ApiService.get(
            '/products',
            `?name=${this.nameQuery}`
          )

          Promise.all([productsPromise]).then(values => {
            // this.products = []
            // this.products = [...values[0].data['hydra:member']]

            this.paginationEntries = []
            this.paginationEntries = [...values[0].data['hydra:member']]
          })
        }

        if (type === 'sku') {
          const productsPromise = ApiService.get(
            '/products',
            `?sku=${this.skuQuery}`
          )

          Promise.all([productsPromise]).then(values => {
            // this.products = []
            // this.products = [...values[0].data['hydra:member']]

            this.paginationEntries = []
            this.paginationEntries = [...values[0].data['hydra:member']]
          })
        }
      }, this.debounceMilliseconds)
    },
    orderProductsByPrice() {
      // ?order[price]=asc&page=1
      this.priceOrder.isActive = true
      if (this.priceOrder.query === 'asc') {
        this.priceOrder.query = 'desc'
      } else {
        this.priceOrder.query = 'asc'
      }

      this.isProductsLoaded = false
      this.getProducts(
        '/products',
        `?properties[]=id&properties[]=featuredImage&properties[]=name&properties[]=price&properties[]=specialPrice&properties[]=sku&properties[]=category&properties[]=brand&order[price]=${this.priceOrder.query}`
      ).then(({ data }) => {
        this.paginationEntries = []
        this.paginationEntries = [...data['hydra:member']]
        this.isProductsLoaded = true
      })
    },
    orderProductsByName() {
      // ?order[name]=asc&page=1
      this.nameOrder.isActive = true
      if (this.nameOrder.query === 'asc') {
        this.nameOrder.query = 'desc'
      } else {
        this.nameOrder.query = 'asc'
      }

      this.isProductsLoaded = false
      this.getProducts(
        '/products',
        `?properties[]=id&properties[]=featuredImage&properties[]=name&properties[]=price&properties[]=specialPrice&properties[]=sku&properties[]=category&properties[]=brand&order[name]=${this.nameOrder.query}`
      ).then(({ data }) => {
        // this.products = []
        // this.products = [...data['hydra:member']]
        this.paginationEntries = []
        this.paginationEntries = [...data['hydra:member']]
        this.isProductsLoaded = true
      })
    },
    toggleStockModal() {
      this.$root.$emit('bv::show::modal', 'stock_modal')
    },
    toggleExportProductsModal() {
      this.getCategories(
        '/product_categories',
        '?order[createdAt]=desc&itemsPerPage=90'
      ).then(({ data }) => {
        this.categories = [...data['hydra:member']]

        this.parentCategories = this.categories.filter(
          category => category.parent === undefined
        )
      })
      this.$root.$emit('bv::show::modal', 'export_products_modal')
    },
    filesAdded() {},
    complete() {},
    success(_, response) {
      this.updateStockFileName = '/uploads/' + response
      this.isUpdateStockFileSuccessUpdated = true
    },
    payloadListener(payload) {
      this.paginationEntries = []
      this.paginationEntries = [...payload.entities]
      this.isProductsLoaded = payload.isLoaded
    },
    exportProducts(categoryId, format) {
      // console.log(`${process.env.VUE_APP_API_ENDPOINT}/export/products/${categoryId}/${format}`)
      window
        .open(
          `${process.env.VUE_APP_API_ENDPOINT}/export/products/${categoryId}/${format}`,
          '_blank'
        )
        .focus()
    },
    updateProductsStock(fileName) {
      window
        .open(
          `${
            process.env.VUE_APP_API_ENDPOINT
          }/products/update-stock/${fileName.replace('/uploads/', '')}`,
          '_blank'
        )
        .focus()
    },
    async getCategories(resource, slug = '') {
      try {
        return await ApiService.get(resource, slug)
      } catch (e) {
        throw new Error(e)
      }
    }
  }
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.form-control--category-select,
.form-control--tags-select {
  height: unset !important;

  .vs__dropdown-toggle {
    padding: 0;
    border: 0;
    border-radius: 0;
  }
  .vs__dropdown-menu {
    border: 1px solid #e4e6ef;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

    /* width */
    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    li {
      padding: 0.65rem 1rem;

      &:hover {
        background: #f3f6f9;
        color: #3f4254;
      }
    }
  }

  .vs__open-indicator {
  }
  .vs__dropdown-toggle {
  }
  .vs__clear {
    display: none;
  }
  .vs__selected {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.25rem 0.75rem;
    font-size: 1rem;
    margin: 0;
    position: relative;
    float: left /*rtl:right*/;
    color: #3f4254;
    background: #f3f6f9;
    border: 0;
    border-radius: 0.42rem;
  }
  .vs__no-options {
  }
  .vs__spinner {
  }
  .vs__deselect {
  }
  .vs__active {
  }
  .vs__dropdown-option--highlight {
    background: #ebedf3;
    color: #3f4254;
  }
}
</style>
