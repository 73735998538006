<template>
  <div>
    <div class="d-flex justify-content-end align-items-center flex-wrap">
      <div class="d-flex flex-wrap">
        <button
          v-if="type === 'simple'"
          @click="first"
          :disabled="
            view['hydra:next'].replace('/api/v1/products', '') === '?page=2'
          "
          class="btn btn-icon btn-sm  mr-2 my-1"
          :class="{
            'btn-light-primary':
              view['hydra:next'].replace('/api/v1/products', '') !== '?page=2'
          }"
        >
          <i class="ki ki-double-arrow-back icon-xs"></i>
        </button>
        <button
          v-if="type === 'simple'"
          :disabled="
            view['hydra:next'].replace('/api/v1/products', '') === '?page=2'
          "
          @click="prev"
          class="btn btn-icon btn-sm mr-2 my-1"
          :class="{
            'btn-light-primary':
              view['hydra:next'].replace('/api/v1/products', '') !== '?page=2'
          }"
        >
          <i class="ki ki-arrow-back icon-xs"></i>
        </button>
        <a
          v-if="type === 'advance'"
          href="#"
          class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"
          >...</a
        >
        <a
          v-if="type === 'advance'"
          href="#"
          class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"
          >23</a
        >
        <a
          v-if="type === 'advance'"
          href="#"
          class="btn btn-icon btn-sm border-0 btn-hover-primary active mr-2 my-1"
          >24</a
        >
        <a
          v-if="type === 'advance'"
          href="#"
          class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"
          >25</a
        >
        <a
          v-if="type === 'advance'"
          href="#"
          class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"
          >26</a
        >
        <a
          v-if="type === 'advance'"
          href="#"
          class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"
          >27</a
        >
        <a
          v-if="type === 'advance'"
          href="#"
          class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"
          >28</a
        >
        <a
          v-if="type === 'advance'"
          href="#"
          class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"
          >...</a
        >
        <button
          v-if="type === 'simple'"
          @click="next"
          class="btn btn-icon btn-sm btn-light-primary mr-2 my-1"
        >
          <i class="ki ki-arrow-next icon-xs"></i>
        </button>
        <button
          v-if="type === 'simple'"
          @click="last"
          class="btn btn-icon btn-sm btn-light-primary mr-2 my-1"
        >
          <i class="ki ki-double-arrow-next icon-xs"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'

export default {
  name: 'Pagination',
  props: {
    type: {
      type: String,
      required: true
    },
    endpoint: {
      type: String,
      required: true
    },
    filter: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      entities: [],
      view: null,
      isLoaded: false
    }
  },
  mounted() {
    this.getEntities(this.endpoint, this.filter).then(({ status, data }) => {
      if (status === 200) {
        this.entities = [...data['hydra:member']]
        this.view = data['hydra:view']
        this.isLoaded = true

        this.$emit('payload', {
          entities: this.entities,
          isLoaded: this.isLoaded
        })
      }
    })
  },
  methods: {
    next() {
      this.isLoaded = false
      this.entities = []
      this.$emit('payload', {
        entities: this.entities,
        isLoaded: this.isLoaded
      })

      this.getEntities(
        this.endpoint,
        this.view['hydra:next'].replace('/api/v1/products', '')
      ).then(({ status, data }) => {
        if (status === 200) {
          this.entities = [...data['hydra:member']]
          this.view = data['hydra:view']
          this.isLoaded = true

          this.$emit('payload', {
            entities: this.entities,
            isLoaded: this.isLoaded
          })
        }
      })
    },
    prev() {
      this.isLoaded = false
      this.entities = []
      this.$emit('payload', {
        entities: this.entities,
        isLoaded: this.isLoaded
      })

      this.getEntities(
        this.endpoint,
        this.view['hydra:previous'].replace('/api/v1/products', '')
      ).then(({ status, data }) => {
        if (status === 200) {
          this.entities = [...data['hydra:member']]
          this.view = data['hydra:view']
          this.isLoaded = true

          this.$emit('payload', {
            entities: this.entities,
            isLoaded: this.isLoaded
          })
        }
      })
    },
    first() {},
    last() {},
    async getEntities(resource, slug = '') {
      try {
        return await ApiService.get(resource, slug)
      } catch (e) {
        throw new Error(e)
      }
    }
  }
}
</script>
